<template>
  <div>
    <b-container>
      <h2 class="font-weight-bolder text-center text-colorBlue mt-5 mb-3">
        Patient's Testimonials
      </h2>

      <p class="text-colorText font-weight-bold">
        At MedAsk Tours, we pride ourselves on providing exceptional service to
        our clients, ensuring that every aspect of their medical journey is
        smooth, secure, and stress-free. Our comprehensive approach covers
        everything from visa assistance and transportation to personal security
        and interpreter services, allowing our clients to focus solely on their
        health and well-being.
      </p>

      <b-row class="mt-3 d-flex align-items-center">
        <b-col md="6" v-if="currentTestimonial">
          <h3 class="font-weight-bolder">{{ currentTestimonial.full_name }}</h3>
          <p class="font-weight-bolder p-0">
            {{ currentTestimonial.country }}
          </p>
          <div class="d-flex mb-2">
            <feather-icon
              v-for="n in 5"
              :key="n"
              icon="StarIcon"
              fill="gold"
              stroke="none"
              size="24"
              :style="{
                fill: n <= currentTestimonial.rating ? 'gold' : 'lightgray',
              }"
              class="mr-50"
            />
          </div>
          <p
            class="mb-3 text-colorText font-weight-bold"
            style="text-align: justify; display: block"
          >
            {{ currentTestimonial.review }}
          </p>

          <div class="d-flex justify-content-center my-3">
            <b-button
              @click="prevTestimonial"
              class="bg-primary mr-50"
              pill
              size="sm"
              variant="colorGreen"
            >
              <feather-icon icon="ChevronLeftIcon" size="24" />
            </b-button>
            <b-button
              @click="nextTestimonial"
              pill
              size="sm"
              variant="colorGreen"
            >
              <feather-icon icon="ChevronRightIcon" size="24" />
            </b-button>
          </div>
          <div class="d-flex align-items-center">
            <div class="mr-1">
              <b-avatar size="45" style="z-index: 1">
                <img
                  class="img-fluid"
                  src="@/assets/images/home/user.webp"
                  alt="Doctor Image"
                  style=""
                />
              </b-avatar>
              <b-avatar size="45" style="z-index: 2; margin-left: -20px">
                <img
                  class="img-fluid"
                  src="@/assets/images/home/user1.webp"
                  alt="Doctor Image"
                  style=""
                />
              </b-avatar>
              <b-avatar size="45" style="z-index: 3; margin-left: -20px">
                <img
                  class="img-fluid"
                  src="@/assets/images/home/user2.webp"
                  alt="Doctor Image"
                  style=""
                />
              </b-avatar>
              <b-avatar size="45" style="z-index: 4; margin-left: -20px">
                <img
                  class="img-fluid"
                  src="@/assets/images/home/user3.webp"
                  alt="Doctor Image"
                  style=""
                />
              </b-avatar>
            </div>
            <p class="m-0 text-colorGreen font-weight-bolder">
              1K+ Satisfied Patients
            </p>
          </div>
        </b-col>
        <b-col md="6" class="text-center" v-if="currentTestimonial">
          <img
            :src="currentTestimonial.image"
            alt="Doctor Image"
            height="340px"
            class="mt-2 mt-md-0"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import util from "@/util.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  mixins: [util],
  components: {
    ToastificationContent,
  },
  data() {
    return {
      count_hospital: 0,
      testimonials: [],
      currentIndex: 0,
      intervalId: null,
    };
  },

  methods: {
    ...mapActions({
      getTestimonialPublic: "appData/getTestimonialPublic",
    }),
    nextTestimonial() {
      this.currentIndex = (this.currentIndex + 1) % this.testimonials.length;
    },
    prevTestimonial() {
      this.currentIndex =
        (this.currentIndex - 1 + this.testimonials.length) %
        this.testimonials.length;
    },
    startAutoScroll() {
      this.intervalId = setInterval(this.nextTestimonial, 5000); // Change every 5 seconds
    },
    stopAutoScroll() {
      clearInterval(this.intervalId);
    },
  },
  async mounted() {
    try {
      const res = await this.getTestimonialPublic({});
      if (res.status === 200) {
        this.testimonials = res.data;
      }
    } catch (error) {
      this.displayError(error);
    }
    this.startAutoScroll();
  },
  beforeDestroy() {
    this.stopAutoScroll();
  },
  computed: {
    currentTestimonial() {
      return this.testimonials[this.currentIndex];
    },
    ...mapState({
      cart: (state) => state.appData.cart,
    }),
  },
};
</script>

<style scoped>
.my-col {
  transition: 0.5s ease;
}
.my-col:hover {
  transform: translateY(-8px);
}
.logo-container {
  display: block;
  position: relative; /* Ensure absolute positioning works for child elements */
}

.icon-default,
.icon-hover {
  top: 0;
  left: 0;
}

.icon-hover {
  display: none; /* Hide hover image by default */
}

.logo-container:hover .icon-default {
  display: none; /* Hide default image on hover */
}

.logo-container:hover .icon-hover {
  display: block; /* Show hover image on hover */
}
</style>
