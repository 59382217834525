<template>
  <div class="my-5">
    <b-container>
      <h2 class="font-weight-bold text-colorBlue text-center pb-2">
        For Overseas <span class="font-weight-bolder">Pakistanis</span>
      </h2>
      <h5 class="font-weight-bold">
        MedAsk Tours is eagerly looking forward to welcome our overseas
        Pakistanis to find best healthcare treatment options at our great
        facilities around Pakistan. At this moment we are in Rawalpindi but we
        are expanding to other cities in near future.
        <h5 class="mt-50">
          MedAsk Tours through its provider hospitals have arranged great
          discount and offers to provide a wonderful opportunity for overseas
          Pakistanis.
        </h5>

        We do provide
        <span class="font-weight-bolder text-colorBlue">fast lane help</span> in
        case of medical emergencies for overseas Pakistanis, who need a quick
        return back to pakistan get some medical treatment.
      </h5>
      <div
        class="w-100 d-flex justify-content-center mt-3 mb-2"
        v-if="!showEmergencyForm"
      >
        <div
          class="d-flex align-items-center justify-content-center mb-2 w-75 bg-colorBlue p-2 rounded"
        >
          <div>
            <b-img
              src="@/assets/images/overseas/emergency.png"
              height="30"
              alt="Image"
            ></b-img>
          </div>
          <div>
            <p class="font-weight-bolder text-light p-0 m-0 ml-1">
              You can apply for Overseas Pakistani Repatriation Emergency
              Services if required.
            </p>
          </div>
          <div>
            <b-button
              variant="success"
              size="sm"
              class="ml-1"
              @click="toggleForms()"
            >
              Click Here
            </b-button>
          </div>
        </div>
      </div>
      <div
        class="w-100 d-flex justify-content-center mt-3 mb-2"
        v-if="showEmergencyForm"
      >
        <div
          class="d-flex align-items-center justify-content-center mb-2 w-75 bg-colorBlue p-2 rounded"
          v-if="showEmergencyForm"
        >
          <div>
            <p class="font-weight-bolder text-light p-0 m-0 ml-1">
              If there is no emergency please switch to normal case form .
            </p>
          </div>
          <div>
            <b-button
              variant="success"
              size="sm"
              class="ml-1"
              @click="toggleForms()"
            >
              Switch to normal form
            </b-button>
          </div>
        </div>
      </div>

      <b-card class="mt-4 p-0 h-100 bg-colorBG" v-if="!showEmergencyForm">
        <h2 class="mb-2 text-colorText font-weight-bolder">
          Register for Healthcare Support
        </h2>
        <validation-observer ref="healthPackageCreateFormValidation">
          <b-form @submit.prevent>
            <b-row>
              <b-col md="4" sm="6">
                <b-form-group label-for="name">
                  <template #label>
                    Full Name <span class="text-danger">*</span>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    :rules="{ required }"
                  >
                    <b-form-input
                      id="name"
                      v-model="name"
                      v-restrict="regex"
                      :state="errors.length > 0 ? false : null"
                      name="name"
                      placeholder="Full Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4" sm="6">
                <b-form-group label-for="mobilenumber">
                  <template #label>
                    Contact Number <span class="text-danger">*</span>
                    <i> (must be overseas phone)</i>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="mobileNumber"
                    rules="required|integer"
                  >
                    <b-form-input
                      id="mobileNumber"
                      v-model="contactNumber"
                      :state="errors.length > 0 ? false : null"
                      name="name"
                      placeholder="Contact Number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4" sm="6">
                <b-form-group label-for="email">
                  <template #label>
                    Email <span class="text-danger">*</span>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="email"
                    :rules="{ required, email }"
                  >
                    <b-form-input
                      id="email"
                      v-model="email"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Email"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="my-2">
              <b-col md="4" sm="6">
                <b-form-group label-for="gender">
                  <template #label>
                    Gender <span class="text-danger">*</span>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="gender"
                    :rules="{ required }"
                  >
                    <b-form-select
                      id="gender"
                      v-model="gender"
                      :state="errors.length > 0 ? false : null"
                      :options="genderOptions"
                      placeholder="Select Gender"
                    ></b-form-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4" sm="6">
                <b-form-group label-for="passportNumber">
                  <template #label>
                    Passport Number <span class="text-danger">*</span>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="passportNumber"
                    rules="required"
                  >
                    <b-form-input
                      id="passportNumber"
                      v-model="passportNumber"
                      :state="errors.length > 0 ? false : null"
                      name="passportNumber"
                      placeholder="Passport Number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4" sm="6">
                <b-form-group label-for="nic">
                  <template #label>
                    NICOP/ NIC <span class="text-danger">*</span>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="nic"
                    rules="required|integer"
                  >
                    <b-form-input
                      id="nic"
                      v-model="nic"
                      :state="errors.length > 0 ? false : null"
                      name="nic"
                      placeholder="NICOP/ NIC "
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="my-2 justify-content-center align-items-start">
              <b-col md="4" sm="6">
                <b-form-group label-for="refferalCode">
                  <template #label> Refferal Code </template>
                  <validation-provider
                    #default="{ errors }"
                    name="refferalCode"
                    rules="integer"
                  >
                    <b-form-input
                      id="refferalCode"
                      v-model="refferalCode"
                      :state="errors.length > 0 ? false : null"
                      name="refferalCode"
                      placeholder="Refferal Code"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <p class="font-weight-bolder text-danger p-0 m-0">
                  If reference number provided enter your reference number
                  otherwise leave it empty.
                </p>
              </b-col>
              <b-col md="8" sm="6">
                <b-form-group label-for="postalAddress">
                  <template #label>
                    Post Address <span class="text-danger">*</span>
                    <i> (must be overseas)</i>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="postalAddress"
                    :rules="{ required }"
                  >
                    <b-form-input
                      id="postalAddress"
                      v-model="postalAddress"
                      :state="errors.length > 0 ? false : null"
                      name="postalAddress"
                      placeholder="Post Address"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <b-form-group label-for="message">
                  <template #label>
                    Message
                    <i>
                      (Please Provide medical Detail & Medical issue)</i
                    ></template
                  >

                  <validation-provider
                    #default="{ errors }"
                    name="Message"
                    :rules="{ required }"
                  >
                    <b-form-textarea
                      id="message"
                      v-model="messageText"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Message"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <b-form-group>
                  <b-form-checkbox v-model="agreeToTerms" required>
                    I agree to MedAsk Tours Pakistan's
                    <router-link
                      :to="{ name: 'TermsOfServices' }"
                      class="text-colorGreen"
                    >
                      Terms and Conditions
                    </router-link>
                    , I have read the
                    <router-link
                      :to="{ name: 'PrivacyPolicy' }"
                      class="text-colorGreen"
                      >Privacy Policy</router-link
                    >
                    and I consent to the processing of my provided details,
                    including health data, by MedAsk Tours Pakistan for the
                    purpose of obtaining quotes.
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>

            <div class="text-center">
              <b-button
                type="submit"
                variant="success"
                class="mt-2"
                :disabled="!agreeToTerms"
                @click="validateForm"
              >
                Request Support
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-card>

      <transition name="fade-slide">
        <b-card class="mt-4 p-0 h-100 bg-colorBG" v-if="showEmergencyForm">
          <h2 class="mb-2 text-danger font-weight-bolder">
            Register for Healthcare Support (Emergency Form)
          </h2>
          <validation-observer ref="healthPackageEmergencyCreateFormValidation">
            <b-form @submit.prevent>
              <b-row>
                <b-col md="4" sm="6">
                  <b-form-group label-for="name">
                    <template #label>
                      Full Name <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="name"
                      :rules="{ required }"
                    >
                      <b-form-input
                        id="name"
                        v-model="name2"
                        v-restrict="regex"
                        :state="errors.length > 0 ? false : null"
                        name="name"
                        placeholder="Full Name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4" sm="6">
                  <b-form-group label-for="mobilenumber">
                    <template #label>
                      Contact Number <span class="text-danger">*</span>
                      <i> (must be overseas phone)</i>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="mobileNumber"
                      rules="required|integer"
                    >
                      <b-form-input
                        id="mobileNumber"
                        v-model="contactNumber2"
                        :state="errors.length > 0 ? false : null"
                        name="name"
                        placeholder="Contact Number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4" sm="6">
                  <b-form-group label-for="email">
                    <template #label>
                      Email <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="email"
                      :rules="{ required, email }"
                    >
                      <b-form-input
                        id="email"
                        v-model="email2"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Email"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-2">
                <b-col md="4" sm="6">
                  <b-form-group label-for="gender">
                    <template #label>
                      Gender <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="gender"
                      :rules="{ required }"
                    >
                      <b-form-select
                        id="gender"
                        v-model="gender"
                        :state="errors.length > 0 ? false : null"
                        :options="genderOptions"
                        placeholder="Select Gender"
                      ></b-form-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4" sm="6">
                  <b-form-group label-for="passportNumber">
                    <template #label>
                      Passport Number <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="passportNumber"
                      rules="required"
                    >
                      <b-form-input
                        id="passportNumber"
                        v-model="passportNumber2"
                        :state="errors.length > 0 ? false : null"
                        name="passportNumber"
                        placeholder="Passport Number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4" sm="6">
                  <b-form-group label-for="nic">
                    <template #label>
                      NICOP/ NIC <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="nic"
                      rules="required|integer"
                    >
                      <b-form-input
                        id="nic"
                        v-model="nic2"
                        :state="errors.length > 0 ? false : null"
                        name="nic"
                        placeholder="NICOP/ NIC "
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-2">
                <b-col md="4" sm="6">
                  <b-form-group label-for="city">
                    <template #label>
                      Destination city in Pakistan
                      <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="city"
                      :rules="{ required }"
                    >
                      <b-form-input
                        id="city"
                        v-model="city2"
                        v-restrict="regex"
                        :state="errors.length > 0 ? false : null"
                        name="city"
                        placeholder="Destination city in Pakistan"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4" sm="6">
                  <b-form-group label-for="emergencyType">
                    <template #label>
                      Emergency type <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="emergencyType"
                      :rules="{ required }"
                    >
                      <b-form-input
                        id="emergencyType"
                        v-model="emergencyType2"
                        v-restrict="regex"
                        :state="errors.length > 0 ? false : null"
                        name="emergencyType"
                        placeholder="Emergency type"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4" sm="6">
                  <b-form-group label-for="refferalCode">
                    <template #label> Refferal Code </template>
                    <validation-provider
                      #default="{ errors }"
                      name="refferalCode"
                      rules="integer"
                    >
                      <b-form-input
                        id="refferalCode"
                        v-model="refferalCode2"
                        :state="errors.length > 0 ? false : null"
                        name="refferalCode"
                        placeholder="Refferal Code"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-2 justify-content-center align-items-center">
                <b-col md="12" sm="12">
                  <b-form-group label-for="postalAddress">
                    <template #label>
                      Post Address <span class="text-danger">*</span>
                      <i> (must be overseas)</i>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="postalAddress"
                      :rules="{ required }"
                    >
                      <b-form-input
                        id="postalAddress"
                        v-model="postalAddress2"
                        v-restrict="regex"
                        :state="errors.length > 0 ? false : null"
                        name="postalAddress"
                        placeholder="Post Address"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <b-form-group label-for="message">
                    <template #label>
                      Message
                      <i>(Please Provide medical Detail & Medical issue)</i>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Message"
                      :rules="{ required }"
                    >
                      <b-form-textarea
                        id="message"
                        v-model="messageText2"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Message"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <b-form-group>
                    <b-form-checkbox v-model="agreeToTerms2" required>
                      I agree to MedAsk Tours Pakistan's
                      <router-link
                        :to="{ name: 'TermsOfServices' }"
                        class="text-colorGreen"
                      >
                        Terms and Conditions
                      </router-link>
                      , I have read the
                      <router-link
                        :to="{ name: 'PrivacyPolicy' }"
                        class="text-colorGreen"
                        >Privacy Policy</router-link
                      >
                      and I consent to the processing of my provided details,
                      including health data, by MedAsk Tours Pakistan for the
                      purpose of obtaining quotes.
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>

              <div class="text-center">
                <b-button
                  type="submit"
                  variant="success"
                  class="mt-2"
                  :disabled="!agreeToTerms2"
                  @click="validateFormEmergency"
                >
                  Request Support
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </b-card>
      </transition>
    </b-container>
  </div>
</template>

<script>
import { getAllCountries } from "countries-and-timezones";
import VueSelectPaginated from "@/components/ui/VueSelectPaginated.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import util from "@/util.js";
import { mapActions } from "vuex";
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { Country, State, City } from "country-state-city";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

export default {
  components: {
    FormWizard,
    TabContent,
    ValidationProvider,
    ValidationObserver,
    VueSelectPaginated,
    ToastificationContent,
    FeatherIcon,
    vueDropzone: vue2Dropzone,
    Country,
    State,
    City,
    VuePhoneNumberInput,
  },
  mixins: [util],

  data() {
    return {
      name: "",
      contactNumber: "",
      email: "",
      gender: "",
      passportNumber: "",
      nic: "",
      refferalCode: "",
      postalAddress: "",
      isEmergency: "",
      messageText: "",
      emergencyType: "",
      city: "",
      agreeToTerms: false,
      agreeToTerms2: false,
      name2: "",
      contactNumber2: "",
      email2: "",
      gender2: "",
      passportNumber2: "",
      nic2: "",
      refferalCode2: "",
      postalAddress2: "",
      isEmergency2: "",
      messageText2: "",
      emergencyType2: "",
      city2: "",
      regex: /^[a-zA-Z ]*$/,
      required: required,
      showEmergencyForm: false,
      showNormalForm: true,
      genderOptions: [
        { value: "Male", text: "Male" },
        { value: "Female", text: "Female" },
      ],
    };
  },
  async mounted() {},

  computed: {},
  methods: {
    ...mapActions({
      createOverseasQuote: "appData/createOverseasQuote",
    }),

    toggleForms() {
      this.showEmergencyForm = !this.showEmergencyForm;
    },

    async validateForm() {
      const success =
        await this.$refs.healthPackageCreateFormValidation.validate();
      if (success) {
        await this.submit();
      }
    },
    async validateFormEmergency() {
      const success =
        await this.$refs.healthPackageEmergencyCreateFormValidation.validate();
      if (success) {
        await this.submitEmergency();
      }
    },
    async submitEmergency() {
      if (this.gender.text === "") {
        gender.text = "";
      }
      try {
        const bookingData = {
          full_name: this.name2,
          contact_number: this.contactNumber2,
          email: this.email2,
          gender: this.gender2 ? this.gender2 : "",
          passport_number: this.passportNumber2,
          cnic: this.nic2,
          referral_code: this.refferalCode2,
          address: this.postalAddress2,
          message: this.messageText2,
          is_emergency: true,
          destination_city: this.city2 ? this.city2 : "",
          emergency_type: this.emergencyType2 ? this.emergencyType2 : "",
        };
        this.showEmergencyForm = false;
        const formData = new FormData();
        for (const key in bookingData) {
          formData.append(key, bookingData[key]);
        }
        const response = await this.createOverseasQuote(formData);

        if (response.status === 200) {
          this.$swal(
            {
              title:
                "Thank you for contacting Medask Tours! We’ll get back to you shortly.",
              icon: "success",
            },
            {
              position: "top-right",
            }
          );
          this.resetOverseas();
        } else {
          this.$swal(
            {
              title:
                "Oops! Something went wrong with your submission. Please try again or contact us directly at medtours@medask.com.pk.",
              variant: "danger",
              icon: "BellIcon",
            },
            {
              position: "top-right",
            }
          );
        }
      } catch (error) {
        this.displayError(error);
      }
    },
    async submit() {
      if (this.gender.text === "") {
        gender.text = "";
      }
      try {
        const bookingData = {
          full_name: this.name,
          contact_number: this.contactNumber,
          email: this.email,
          gender: this.gender ? this.gender : "",
          passport_number: this.passportNumber,
          cnic: this.nic,
          referral_code: this.refferalCode,
          address: this.postalAddress,
          message: this.messageText,
          is_emergency: false,
          destination_city: this.city ? this.city : "",
          emergency_type: this.emergencyType ? this.emergencyType : "",
        };
        this.showEmergencyForm = false;
        const formData = new FormData();
        for (const key in bookingData) {
          formData.append(key, bookingData[key]);
        }
        const response = await this.createOverseasQuote(formData);

        if (response.status === 200) {
          this.$swal(
            {
              title:
                "Thank you for contacting Medask Tours! We’ll get back to you shortly.",
              icon: "success",
            },
            {
              position: "top-right",
            }
          );
          this.reset();
        } else {
          this.$swal(
            {
              title:
                "Oops! Something went wrong with your submission. Please try again or contact us directly at medtours@medask.com.pk.",
              variant: "danger",
              icon: "BellIcon",
            },
            {
              position: "top-right",
            }
          );
        }
      } catch (error) {
        this.displayError(error);
      }
    },
    reset() {
      this.name = "";
      this.contactNumber = "";
      this.email = "";
      this.gender = "";
      this.passportNumber = "";
      this.nic = "";
      this.refferalCode = "";
      this.postalAddress = "";
      this.messageText = "";
      this.emergencyType = "";
      this.city = "";
      this.agreeToTerms = false;
    },
    resetOverseas() {
      this.name2 = "";
      this.contactNumber2 = "";
      this.email2 = "";
      this.gender2 = "";
      this.passportNumber2 = "";
      this.nic2 = "";
      this.refferalCode2 = "";
      this.postalAddress2 = "";
      this.messageText2 = "";
      this.emergencyType2 = "";
      this.city2 = "";
      this.agreeToTerms2 = false;
    },
  },
};
</script>

<style scoped>
.fade-slide-enter-active,
.fade-slide-leave-active {
  transition: all 3s ease;
}

.fade-slide-enter,
.fade-slide-leave-to {
  opacity: 0;
  transform: translateX(-200px);
}
.fade-slide2-enter-active,
.fade-slide2-leave-active {
  transition: all 3s ease;
}

.fade-slide2-enter,
.fade-slide2-leave-to {
  opacity: 0;
  transform: translateX(600px);
}
</style>
